.App {
  text-align: center;
}

main {
  margin-left: 280px !important;
  padding: 8px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.sidebar_card {
  position: fixed !important;
  bottom: 0;
  max-width: 279px;
  margin-bottom: 0px !important;
  left: 0;
  z-index: 9;
  width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #d0d5dd;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d0d5dd;
}

.objective-cards {
  border: 2px solid #eaecf0;
  box-shadow: 2px 2px 4px #ccc;
}

.objective-cards-selected {
  border: 2px solid #5a27dd;
  box-shadow: 2px 2px 4px #ccc;
}

@media only screen and (max-width: 991px) {
  main {
    padding: 0px 10px !important;
    // margin: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  main {
    padding: 0px 10px !important;
    // margin: 0px !important;
  }
}

@media only screen and (max-width: 599px) {
  main {
    padding: 0px 10px !important;
    margin: 0px !important;
  }
}

@media only screen and (max-width: 350px) {
  main {
    padding: 0px 10px !important;
    margin: 0px !important;
  }
}

mail-link {
  font-size: 14px;
}

.animate-button {
  .animation-button {
    background-image: none;
    padding: 10px 25px !important;
    border-radius: 4px !important;
    background-size: 300% 100%;
    transition: all 0.5s ease-in-out;
    background-image: linear-gradient(to right, #5a27dd, #5a27dd66, #00e39c);
  }
}

.animation-button:hover {
  opacity: 1;
  background-position: -50% 0;
}

@media only screen and (max-width: 1199px) {
  .mail-link {
    font-size: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-item {
    font-size: 14px;
  }
}

@media only screen and (max-width: 599px) {
  .MainCard.dashboard-block {
    margin-top: 100px;
  }
}

.top-up-payment-hide {
  display: none !important;
}

.top-up-payment-show {
  display: block;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dateCalenderx {
  .rdrDayToday .rdrDayNumber span:after {
    background: #5a27dd;
  }

  border: 1px solid #eff2f7;
  padding: 0px !important;
  > div:nth-child(1) {
    width: 100px;

    > div:nth-child(2) {
      display: none;
    }
  }
  @media (max-width: 870px) {
    flex-direction: column;
    div {
      width: 100% !important;
    }
    div:nth-child(1) {
      div {
        flex-direction: row;
      }
      div:nth-child(1) {
        display: block;
        button {
          float: left;
        }
      }
    }
    > div:nth-child(2) > div:nth-child(2) {
      flex-direction: column;
    }
  }
}

$color_1: #fff;
$font-family_1: 'Core Sans', Arial, sans-serif;

$primary-color: #5828c4;
$secondary-color: #3c72b0;
$tertiary-color: #00db96;
$quaternary-color: #329d77;

body {
  margin: 0;
  padding: 0;
  background: #000;
}


@keyframes animateButton {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 500%;
  }
}

.animateButtonLinkCopy {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 36px;
  width: 140px;
  border-radius: 8px;
  padding: 5px 15px;

  transform: translate(1%, 0%);
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  // font-weight: 500;
  box-sizing: border-box;
  background: linear-gradient(
    90deg,
    $primary-color,
    $secondary-color,
    $tertiary-color,
    $quaternary-color,
    $primary-color
  );
  background-size: 500%;
  cursor: pointer;
  animation: animateButton 16s linear infinite;
  line-height: 27px;
  letter-spacing: 0.03em;

  &:hover {
    color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    z-index: -1;
    background: linear-gradient(
      90deg,
      $primary-color,
      $secondary-color,
      $tertiary-color,
      $quaternary-color,
      $primary-color
    );
    background-size: 500%;
    border-radius: 15px;
    opacity: 0;
    transition: 1s;
  }

  @media (max-width: 767px) {
    width: 140px;
  transform: translate(-10%, 0%);
  }

  &:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: animateButton 16s linear infinite;
  }
}
